.bannerBlock {
    width: 100%;
    height: 100vh;
    background-image: url('../../resources/img/mainBlock.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    img {
        width: 100%;
        height: 100%;
    }
    .button {
        &__yellow {
            display: none;
        }
    }
    
    @media (max-width: 576px) {

        background-image: url('../../resources/img/mobile_mainBlock.jpg');
        height: calc(100vh - 76px);

        img {
            height: calc(100vh - 76px);
            object-fit: contain;
        }
        
        .button {
            &__yellow {
                position: absolute;
                top: auto;
                bottom: 140px;
                left: 50%;
                transform: translate(-50%, 0);
                display: flex;
                &:hover {
                    transform: translate(-50%, 0);
                }
                &:nth-child(2) {
                    bottom: 80px;
                }
            }
        }
    }
}