.header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    &__menu {
        width: calc((100% - 150px)/2);
        ul{
            display: flex;
            justify-content: space-between;
            padding: 50px 0 0 0;
            li {
                color: #fff;
                font-size: var(--menu_font_size);
                font-weight: 500;
                // margin-right: 40px;
                a {
                    display: inline-block;
                    background-color: transparent;
                    border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
                    &:hover {
                        background-color: #fff;
                        border-radius: 20px;
                        padding: 5px 10px;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &__logo {
        width: 150px;
        margin: 0 30px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        .logo {
            margin-top: -10px;
            img {
                // height: 230px;
            }
        }
    }
}
.header-mobile {
    position: fixed;
    bottom: -100vh;
    left: 0;
    transition: bottom ease 0.6s;
    height: 100vh;
    width: 100%;
    z-index: 101;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    color: var(--header_text_color);
    background-color: var(--main_color);
    text-align: center;
    &.header-mobile--active {
        bottom: 0;
    }
    &__menu {
        text-transform: uppercase;
        font-family: 'Grotesk';
        padding: 40px 0;
        font-size: var(--h5_font_size);
        li {
            a {
                padding: 5px 20px;
                display: inline-block;
            }
        }
    }
    &__text {
        color: var(--prime_color);
        font-size: var(--h6_font_size);
        margin: 10px 0;
    }
    &__footer {
        width: 230px;
        margin: 0 auto;
    }
    &__close {
        display: inline-block;
    }
}

/*------------------- Mobile menu ------------------*/
.hamburger {
    display: none;
    cursor: pointer;
    z-index: 99;
    padding: 20px;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    &.active .bar1 {
      -webkit-transform: rotate(-45deg) translate(-4px, 6px);
      transform: rotate(-45deg) translate(-8px, 6px);
    }
    &.active .bar2 {
      opacity: 0;
    }
    &.active .bar3 {
      -webkit-transform: rotate(45deg) translate(-6px, -8px);
      transform: rotate(45deg) translate(-8px, -6px);
    }
    .bars {
        margin-right: 20px;
    }
    .bar1,
    .bar2,
    .bar3 {
        height: 4px;
        width: 35px;
        /* background: linear-gradient(122.85deg, var(--blue) 0%, var(--white) 99.25%); */
        background-color: var(--main_color);
        transition: 0.4s;
        margin: 6px 0;
        border-radius: 10px;
    }
}

@media (max-width: 1200px) {
    .header {
        &__logo {
            width: 100px;
        }
    }
}
@media (max-width: 576px) {
    .mobile_menu {
      display: inline-block;
    }
    .header {
        justify-content: center;
        &__menu {
            display: none;
        }
    }
    .hamburger {
        display: flex;
    }
}

