.contentBlock {
    overflow: hidden;
    .section {
        &:nth-child(2n) {
            .row {
                flex-direction: row-reverse;
            }
        }
        &__image {
            width: calc(50% - 50px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__image-block {
            position: relative;
            border-radius: 30px;
            overflow: hidden;
            cursor: pointer;
            img {
                width: 100%;
                max-height: 350px;
                border-radius: 30px;
            }
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &__text {
            width: calc(50% - 20px);
        }
        &__bg {
            background-color: var(--section_bg_color);
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            border-radius: 30px;
            padding: 50px 40px;
        }
    }


    @media (max-width: 576px) {
        .section {
            &__image {
                width: 100%;
                margin-bottom: 20px;
            }
            &__image-block {
                svg {
                    width: 50px;
                    height: 50px;
                }
            }
            &__text {
                width: 100%;
            }
            &__bg {
                padding: 30px 0;
            }
            &#national {
              margin-bottom: 70px;
            }
        }
    }
}

