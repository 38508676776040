@charset "UTF-8";

/*********************** Reset.css - сброс стилей *****************/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
strong,
center,
ol,
ul,
li,
form,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
canvas,
footer,
header,
menu,
nav,
section,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255, 0) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}
:focus {
  outline: none;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
  transition: .3s;
}
a:hover {
  color: var(--main_color);
  transform: scale(1.05);
}
li {
  list-style-type: none;
}
input {
  border: none;
}
/************************************************************************/

html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 24px;
  font-weight: normal;
  color: var(--main_text_color);
}
section {
  position: relative;
  margin: 70px 0;
}
section:first-child {
    margin-top: 0;
    padding-top: 0;
}
.antifooter {
  flex-grow: 1;
}
footer {
  flex-shrink: 0;
}
img {
  max-width: 100%;
  object-fit: cover;
}
#back-top {
  display: none;
  cursor: pointer;
}
#back-top {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 99;
  font-size: 32px;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
// .row {
//     display: flex;
// }
/* .col-5 {
    width: 50%;
} */
.bg_wrap {
  position: relative;
}
.bg_wrap:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .5;
}
.prime__color {
  color: var(--main_color);
}


/*------------------------------ Scroll ----------------------------------*/
::-webkit-scrollbar {
  width: 8px;
  /* ширина для вертикального скролла */
  background-color: #fff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: var(--main_color);
  border-radius: 10px;
  /* box-shadow: inset 1px 1px 1px #fff; */
}

.container {
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
}
@media (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}
@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  a:hover {
    color: inherit;
  }
}
