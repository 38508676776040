.modal {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #364687cc;
  backdrop-filter: blur(1px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
  &__body {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    max-width: 900px;
    // max-height: calc(100vh - 100px);
    background-color: #ededed;
    color: var(--main_text_color);
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    transition: all 300ms;
    transform: translate(-50%, -50%) scale(0.9);
    &.modal--active {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &--active {
    opacity: 1;
    visibility: visible;
  }
  &__content {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 30px;
    &::-webkit-scrollbar-thumb {
      background: var(--prime_color);
    }
    &::-webkit-scrollbar {
      border-radius: 10px;
    }
  }
  &__close {
    position: absolute;
    right: 1%;
    top: 1%;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    z-index: 110;
    line-height: 1;
    color: var(--main_color);
    padding: 10px;
  }
  &--about {
    &.modal__body {
      border-radius: 10px;
      padding: 70px 20px 70px 50px;
    }
    .modal__header {
      margin-bottom: 20px;
    }
    .modal__title {
      font-family: 'Grotesk';
      font-size: var(--h1_font_size);
      color: var(--text_color);
      text-transform: uppercase;
      &-text {
        color: var(--prime_color);
        text-transform: none;
        margin-bottom: 10px;
      }
    }
    .modal__image {
      margin-right: 40px;
    }
    .modal__row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-top: 30px;
    }
    .modal__text {
      margin-bottom: 15px;
    }
    .modal__col {
      margin-bottom: 20px;
    }
  }
  &--video {
    &.modal__body {
      height: 505px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .modal__close {
      top: -50px;
      right: 0px;
      color: #ffffff;
    }
  }


  @media (min-width: 1600px) {
    &--video {
      &.modal__body {
        max-width: 1200px;
        height: 675px;
      }
    }
  }
  
  @media (max-width: 576px) {
      &__content {
        max-height: calc(100vh - 250px);
      }
      &__body {
        .modal__close {
          top: auto;
          bottom: -70px;
          right: 50%;
          transform: translate(50%, 0);
          color: #ffffff;
          padding: 20px;
        }
      }
      &--about {
        &.modal__body {
          padding: 20px 10px;
          border-radius: 20px;
          box-sizing: border-box;
          ::-webkit-scrollbar {
            width: 4px;
          }
        }
        .modal__row {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
        .modal__title-text {
          margin-bottom: 0;
        }
      }
      &--video {
        &.modal__body {
          height: 300px;
        }
      }
    }
}
