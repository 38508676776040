.aboutBlock {
    .section {
        &__image {
            img {
                max-height: 570px;
                cursor: default;
            }
        }
        &__text {
            display: flex;
            flex-direction: column;
        }
    }
}

@media (max-width: 576px) {
    .aboutBlock {
        .button {
            &__flex {
                justify-content: center;
            }
            &__yellow {
                display: none;
            }
        }
    }
}