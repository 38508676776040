footer {
    .footer {
        display: flex;
        justify-content: center;
        margin: 50px 0;
        &__text {
            p {
                color: var(--footer_color);
                font-size: var(--h6_font_size);
            }
        }
        &__left {
            text-align: right;
        }
        &__right {
            text-align: left;
        }
        &__logo {
            margin: 0 40px;
        }
    }
}

@media (max-width: 576px) {
    footer {
        display: none;
        .footer {
            flex-wrap: wrap;
        }
    }
}