// @font-face {
//   font-family: "opensans";
//   src: url("@/assets/fonts/opensans/Opensans-Regular.ttf");
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
    font-family: "Grotesk";
    src: url("../resources/fonts/Cy_Grotesk_Wide_Bold.ttf");
    font-weight: 700;
    font-style: normal;
  }
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');


:root {
    --header_text_color: #ffffff;
    --main_color: #364687;
    --section_bg_color: #ffffff;
    --main_bg_color: #E6E6E6;
    --text_color: #364687;
    --prime_color: #04A0DE;
    --footer_color: #9D9D9D;
    --border_color: #000000;

    --menu_font_size: 16px;
    --text_font_size: 18px;
    --h1_font_size: 40px;
    --h2_font_size: 30px;
    --h3_font_size: 24px;
    --h4_font_size: 20px;
    --h5_font_size: 18px;
    --h6_font_size: 12px;
}

h1, h2, p {
    color: var(--text_color);
    line-height: 140%;
}
h1 {
    font-size: var(--h1_font_size);
    margin-top: 40px;
}
h2 {
    font-size: var(--h2_font_size);
}
p {
  font-size: var(--text_font_size);
}
.title {
    font-family: 'Grotesk';
    text-transform: uppercase;
    margin-bottom: 30px;
    color: var(--text_color);
    &__mobile {
        display: none;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // grid-gap: 40px;
}
.row-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}
.col {
    &-6 {
        // width: 50%;
    }
}
.d-flex {
    display: flex;
}
.direction-column {
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.justify-around {
    justify-content: space-around;
}
.justify-between {
    justify-content: space-between;
}
.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: var(--h5_font_size);
    color: var(--header_text_color);
    padding: 10px 30px;
    border-radius: 60px;
    gap: 10px;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
        transform: scale(1.05);
    }
    &__wrap {
        margin-top: 40px;
    }
    &__flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    &__blue {
        background: linear-gradient(180deg, #0B9BD8 0%, #3567BC 100%);
    }
    &__yellow {
        background: linear-gradient(180deg, #F0AE34 0%, #DC8400 100%);
    }
}


@media (max-width: 1200px) {
    :root {
      --menu_font_size: 14px;
    }
}
@media (max-width: 576px) {
    .title {
        text-align: center;
        display: none;
        &__mobile {
            display: block;
        }
    }
    :root {
      --menu_font_size: 14px;
      --text_font_size: 14px;
      --h1_font_size: 18px;
      --h2_font_size: 30px;
      --h3_font_size: 24px;
      --h4_font_size: 20px;
      --h5_font_size: 14px;
      --h6_font_size: 12px;
    }
    section {
      margin: 30px 0;
    }
    .button {
        svg {
            width: 17px;
            height: 17px;
        }
        padding: 10px 15px;
    }
}